<template>
  <b-card-actions
    ref="cardA"
    title="List"
    action-refresh
    no-body
    @refresh="getLineGroupList"
  >
    <b-card class="mb-2" no-body>
      <div class="mx-1">
        <b-row>
          <b-col class="mt-1" cols="12" sm="6" md="4">
            <div class="search-filter">
              <HeroInputText
                id="filter"
                v-model="tableConfig.filter"
                class="filter-input"
                placeholder="Search"
                @input="debounce(getLineGroupList, 500)()"
              />
            </div>
          </b-col>
        </b-row>
        <b-table
          show-empty
          striped
          sticky-header="100%"
          :responsive="true"
          :items="items"
          :fields="tableConfig.fields"
          :per-page="0"
          :sort-by="tableConfig.sortBy"
          :sort-direction="tableConfig.sortDirection"
          :no-sort-reset="true"
          :no-local-sorting="true"
          @sort-changed="onTableSortChanged"
        >
          <template #cell(line_group_name)="{ item }">
            <div class="text-truncate" style="max-width: 310px">
              {{ item.line_group_name }}
            </div>
          </template>
          <template #cell(campaign_name)="{ item }">
            <div
              v-if="item.campaign_id"
              class="d-flex align-items-center"
            >
              <div class="text-truncate" style="max-width: 245px">
                {{ item.campaign_name }}
              </div>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none action-button"
                no-caret
                class="dropdown-action"
              >
                <template #button-content>
                  <feather-icon icon="Edit2Icon" stroke="#6e6b7b" />
                </template>
                <b-dropdown-item @click="handleAssignCampaign(item)">
                  Change Campaign
                </b-dropdown-item>
                <b-dropdown-item @click="handleUnassignCampaign(item.line_group_id)">
                  Unassign Campaign
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <span
              v-else
              class="text-assign-campaign"
              @click="handleAssignCampaign(item)"
            >
              Click to assign campaign
            </span>
          </template>

          <template #cell(join_bangkok_time)="{ item }">
            {{ formatEnglishDateTimeDefault(item.join_bangkok_time) }}
          </template>

          <template #cell(leave_bangkok_time)="{ item }">
            {{ formatEnglishDateTimeDefault(item.leave_bangkok_time) }}
          </template>
        </b-table>
        <div class="d-flex align-items-center justify-content-between flex-wrap mx-0 mb-1 px-1 row-gap-3">
          <HeroTablePagination
            v-model="tableConfig.currentPage"
            :per-page="tableConfig.perPage"
            :total-rows="tableConfig.totalRows"
            class="mb-0"
            @input="debounce(getLineGroupList)()"
          />
          <div class="d-flex align-items-center">
            <HeroTablePerPage
              v-model="tableConfig.perPage"
              :options="tableConfig.perPageOptions"
              class="mb-0"
              @input="debounce(getLineGroupList)()"
            >
              <template #label>
                <div />
              </template>
            </HeroTablePerPage>
            <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
          </div>
        </div>
      </div>
    </b-card>

    <AssignCampaignModal
      :group-id="currentGroupId"
      :current-campaign-id="currentCampaignId"
      @assign-success="getLineGroupList"
    />
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import moment from 'moment'
import DebounceMixin from '@/mixins/debounce'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import axiosInstance from '@/libs/axiosInstance'
import AssignCampaignModal from '@/views/line-group/components/AssignCampaignModal.vue'
import DataFormatService from '@/services/DataFormatService'

export default {
  name: 'LineGroup',
  components: {
    AssignCampaignModal,
    HeroTablePagination,
    HeroTableStatus,
    HeroTablePerPage,
    HeroInputText,
    BCardActions,
  },
  mixins: [DebounceMixin],
  data() {
    return {
      currentGroupId: '',
      currentCampaignId: '',
      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'line_group_name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'GROUP NAME',
            key: 'line_group_name',
            sortable: true,
            thStyle: { minWidth: '310px' },
          },
          {
            label: 'GROUP ID',
            key: 'line_group_id',
            sortable: true,
            thStyle: { minWidth: '150px' },
          },
          {
            label: 'CAMPAIGN NAME',
            key: 'campaign_name',
            sortable: true,
            thStyle: { minWidth: '245px' },
          },
          {
            label: 'JOIN TIME',
            key: 'join_bangkok_time',
            sortable: true,
            thStyle: { minWidth: '210px' },
          },
          {
            label: 'LEAVE TIME',
            key: 'leave_bangkok_time',
            sortable: true,
            thStyle: { minWidth: '210px' },
          },
        ],
      },
    }
  },
  mounted() {
    this.$store.dispatch('UnmappedLeadCampaignOption/fetchOptions')
    this.getLineGroupList()
  },
  methods: {
    formatEnglishDateTimeDefault: DataFormatService.formatEnglishDateTimeDefault,

    async handleAssignCampaign({ line_group_id: lineGroupId, campaign_id: campaignId }) {
      this.currentGroupId = lineGroupId
      this.currentCampaignId = campaignId || ''

      await this.$nextTick()

      this.$bvModal.show('assign-campaign-modal')
    },

    onTableSortChanged(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.getLineGroupList()
    },

    async handleUnassignCampaign(groupId) {
      try {
        const alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Are you sure you want to unassign?' })
        if (!alertResult.value) return

        this.$refs.cardA.showLoading = true

        const url = `/line-groups/${groupId}/unassign-campaign`
        await axiosInstance.patch(url)

        this.$swal({ ...SweetAlert.success, text: 'Unassign Successfully' })

        await this.getLineGroupList()
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        if (this.$refs.cardA) {
          this.$refs.cardA.showLoading = false
        }
      }
    },

    async getLineGroupList() {
      this.$refs.cardA.showLoading = true

      try {
        const {
          filter, sortBy, sortDirection, currentPage, perPage,
        } = this.tableConfig

        const response = await axiosInstance.get('/line-groups', {
          params: {
            search: filter,
            sort_column: sortBy,
            sort_direction: sortDirection,
            page: currentPage,
            per_page: perPage,
          },
        })

        this.tableConfig.currentPage = response.data.data.current_page || 1
        this.tableConfig.perPage = response.data.data.per_page || 1
        this.tableConfig.totalRows = response.data.data.total || 1

        this.items = response.data.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.items = []
        this.tableConfig.totalRows = 0
      } finally {
        if (this.$refs.cardA) {
          this.$refs.cardA.showLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/variables';

.text-assign-campaign {
  font-weight: 500;
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
  flex: 0 0 auto;
}

.text-truncate:hover {
  white-space: normal;
}
</style>
