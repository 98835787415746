var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-actions',{ref:"cardA",attrs:{"title":"List","action-refresh":"","no-body":""},on:{"refresh":_vm.getLineGroupList}},[_c('b-card',{staticClass:"mb-2",attrs:{"no-body":""}},[_c('div',{staticClass:"mx-1"},[_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('div',{staticClass:"search-filter"},[_c('HeroInputText',{staticClass:"filter-input",attrs:{"id":"filter","placeholder":"Search"},on:{"input":function($event){_vm.debounce(_vm.getLineGroupList, 500)()}},model:{value:(_vm.tableConfig.filter),callback:function ($$v) {_vm.$set(_vm.tableConfig, "filter", $$v)},expression:"tableConfig.filter"}})],1)])],1),_c('b-table',{attrs:{"show-empty":"","striped":"","sticky-header":"100%","responsive":true,"items":_vm.items,"fields":_vm.tableConfig.fields,"per-page":0,"sort-by":_vm.tableConfig.sortBy,"sort-direction":_vm.tableConfig.sortDirection,"no-sort-reset":true,"no-local-sorting":true},on:{"sort-changed":_vm.onTableSortChanged},scopedSlots:_vm._u([{key:"cell(line_group_name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"310px"}},[_vm._v(" "+_vm._s(item.line_group_name)+" ")])]}},{key:"cell(campaign_name)",fn:function(ref){
var item = ref.item;
return [(item.campaign_id)?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"245px"}},[_vm._v(" "+_vm._s(item.campaign_name)+" ")]),_c('b-dropdown',{staticClass:"dropdown-action",attrs:{"variant":"link","toggle-class":"text-decoration-none action-button","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"Edit2Icon","stroke":"#6e6b7b"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleAssignCampaign(item)}}},[_vm._v(" Change Campaign ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleUnassignCampaign(item.line_group_id)}}},[_vm._v(" Unassign Campaign ")])],1)],1):_c('span',{staticClass:"text-assign-campaign",on:{"click":function($event){return _vm.handleAssignCampaign(item)}}},[_vm._v(" Click to assign campaign ")])]}},{key:"cell(join_bangkok_time)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatEnglishDateTimeDefault(item.join_bangkok_time))+" ")]}},{key:"cell(leave_bangkok_time)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatEnglishDateTimeDefault(item.leave_bangkok_time))+" ")]}}])}),_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-wrap mx-0 mb-1 px-1 row-gap-3"},[_c('HeroTablePagination',{staticClass:"mb-0",attrs:{"per-page":_vm.tableConfig.perPage,"total-rows":_vm.tableConfig.totalRows},on:{"input":function($event){_vm.debounce(_vm.getLineGroupList)()}},model:{value:(_vm.tableConfig.currentPage),callback:function ($$v) {_vm.$set(_vm.tableConfig, "currentPage", $$v)},expression:"tableConfig.currentPage"}}),_c('div',{staticClass:"d-flex align-items-center"},[_c('HeroTablePerPage',{staticClass:"mb-0",attrs:{"options":_vm.tableConfig.perPageOptions},on:{"input":function($event){_vm.debounce(_vm.getLineGroupList)()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div')]},proxy:true}]),model:{value:(_vm.tableConfig.perPage),callback:function ($$v) {_vm.$set(_vm.tableConfig, "perPage", $$v)},expression:"tableConfig.perPage"}}),_c('HeroTableStatus',{attrs:{"per-page":_vm.tableConfig.perPage,"total-rows":_vm.tableConfig.totalRows}})],1)],1)],1)]),_c('AssignCampaignModal',{attrs:{"group-id":_vm.currentGroupId,"current-campaign-id":_vm.currentCampaignId},on:{"assign-success":_vm.getLineGroupList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }