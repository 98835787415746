<template>
  <b-modal
    id="assign-campaign-modal"
    ref="assign-campaign-modal"
    :title="modalTitle"
    centered
    hide-footer
    no-close-on-backdrop
    size="lg"
    @show="onShow"
    @hidden="campaignId = ''"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <form
      class="assign-campaign-form"
      @submit.prevent="handleSubmit"
    >
      <validation-provider
        #default="{ errors }"
        ref="campaignId"
        name="Campaign"
        rules="required"
      >
        <div class="d-flex align-items-end col-gap-2">
          <HeroVisionCampaignSelect
            id="campaign"
            v-model="campaignId"
            label="Campaign"
            :required="true"
            :clearable="false"
            :loading="$store.getters['UnmappedLeadCampaignOption/getIsLoading']"
            :options="$store.getters['UnmappedLeadCampaignOption/getOptions']"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
            class="campaign-select"
          />
          <HeroButtonAction
            variant="primary"
            type="submit"
            class="btn-action"
          >
            Save
          </HeroButtonAction>
        </div>
      </validation-provider>
    </form>
  </b-modal>
</template>
<script>
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import axiosInstance from '@/libs/axiosInstance'
import HeroVisionCampaignSelect from '@/views/components/form/selects/HeroVisionCampaignSelect.vue'

export default {
  name: 'AssignCampaignModal',
  components: {
    HeroVisionCampaignSelect,
    HeroButtonAction,
  },
  props: {
    groupId: {
      type: String,
      required: true,
    },
    currentCampaignId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOverlay: false,
      campaignId: '',
    }
  },
  computed: {
    modalTitle() {
      return this.currentCampaignId ? 'Change Campaign' : 'Assign Campaign'
    },
  },
  methods: {
    onShow() {
      this.campaignId = this.currentCampaignId
    },

    async handleSubmit() {
      try {
        this.showOverlay = true
        const isCampaignIdValid = (await this.$refs.campaignId.validate()).valid
        if (isCampaignIdValid) {
          const url = `/line-groups/${this.groupId}/assign-campaign/${this.campaignId}`
          await axiosInstance.patch(url)

          this.$bvModal.hide('assign-campaign-modal')
          this.$swal({ ...SweetAlert.success, text: 'Assign Successfully' })
          this.$emit('assign-success')
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.assign-campaign-form {
  .campaign-select {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .btn-action {
    width: 90px;
    min-height: 38px;
    padding: 8px;
  }
}
</style>
